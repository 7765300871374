import React from 'react';

function Header() {
  return (
    <div>
      <h1 className="invHeader"> Inventory </h1>
    </div>
  );
}

export default Header;
